import React from "react";

import {
  Layout,
  SEO,
  BannerStandard,
  CtaPrimary,
  ContainerBackgroundWhite,
  ContainerContentFixed,
  CtaSecondary,
} from "../components";
import {
  ExternalLink,
  InternalLink,
  InternalLinkTypes,
  ExternalLinkTypes,
} from "../components/links";

const Freetrial = () => (
  <Layout pageLink={InternalLinkTypes.FreeTrial}>
    <SEO title={"Free trial"} />
    <BannerStandard
      title={"Free Trial"}
      subTitle={"Instant access to our services"}
    />
    <ContainerBackgroundWhite>
      <ContainerContentFixed>
        <h2>Set up a free trial account</h2>

        <p>
          To trial our software you will first create a free trial account.  This
          trial account will provide access to all of our apps. Once you�ve
          created an account you will arrive in our apps page, select the app
          you wish to trial. You may trial any and all of our apps. To create a
          free trial please agree to our T&Cs and click create free trial
          account.  Note that 
          we will demonstrate pdBidr to you before providing a free trial.  
        </p>
        <p>
          By proceeding, you agree to{" "}
          <InternalLink url={InternalLinkTypes.Terms}>
            terms and conditions
          </InternalLink>
        </p>
        <CtaPrimary>
          <ExternalLink url={ExternalLinkTypes.SignUp}>
            Proceed to create free trial account
          </ExternalLink>
        </CtaPrimary>

        <h2 style={{ marginTop: "32px" }}>
          Already have a pdView Apps account?
        </h2>
        <CtaSecondary>
          <ExternalLink url={ExternalLinkTypes.Login}>Go to Login</ExternalLink>
        </CtaSecondary>
      </ContainerContentFixed>
    </ContainerBackgroundWhite>
  </Layout>
);

export default Freetrial;
